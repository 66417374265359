import React, {useState} from 'react';
import useFetch, {getAuthorizationHeader, handleFormInputChange} from '../utils';
import {TextInput, SelectInput, CheckboxInput} from './fields';
import { API_ENDPOINT } from '../config';
import { toast } from 'react-toastify';


const orgs_url = API_ENDPOINT + "/organizations/";

const ProjectForm = (props) => {
    if (props.match.params.id !== "new") {
        return ProjectEdit(props);
    } else {
        return ProjectCreate(props);
    }
    
}


const ProjectCreate = ({match}) => {
    const {data: organizations, loading: org_loading, error: org_error} = useFetch(orgs_url);

    if (org_loading) {
        return <div>Loading...</div>;
        }
    
    if (org_error) {
        return <div>Error! {org_error.message}</div>;
    }

    const url = API_ENDPOINT + "/projects/";
    
    const project = {
        id: "",
        title: ""

    }

    function handleSubmit(id, data) {
        console.log("Creating Project");
        fetch(url, {
            method: "POST",
            headers: getAuthorizationHeader(),
            body: JSON.stringify(data)
        }).then((response)=>{
            if (response.ok) {
                console.log("success");
                toast.success("Project created");
            }

            if (response.status === 422) {
                toast.warn("Missing fields");
            }
        }).catch(()=> {
            console.log("ERROR !");
            toast.error("Error creating project, Please try again.");
        });
    }

    return (
        <Project project={project} orgs={organizations} handleSubmit={handleSubmit} />
    );
}

const ProjectEdit = ({match}) => {
    const url = API_ENDPOINT + "/projects/" + match.params.id;
    console.log("URL: " + url);
    const { data, loading, error } = useFetch(url);
    const {data: organizations, loading: org_loading, error: org_error} = useFetch(orgs_url);

    if (loading || org_loading) {
        return <div>Loading...</div>;
        }
    
    if (error || org_error) {
        return <div>Error! {error.message}</div>;
    }



    const handleSubmit = (id, data) => {
        console.log("Form submitted Mock!");
        fetch(url, {
            method: "PUT",
            headers: getAuthorizationHeader(),
            body: JSON.stringify(data)
        }).then((response)=>{
            if (response.ok) {
                console.log("success");
                toast("Saved");
                if (response.status === 422) {
                    toast.warn("Missing fields");
                    throw Error("Missing Field");
                }
                return response.json();
            } else if (response.status == 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("token_expires");
                window.location.reload();
            }
        }).catch(()=> {
            console.log("ERROR !");
            toast.error("Error while saving project, please try again.");
        });
    }

    console.log(data);
    return (
        <Project project={data} orgs={organizations} handleSubmit={handleSubmit} />
    );
}


 

const Project = (props) => {
    console.log("Props: " + props);
    const [project, setProject] = useState(props.project)
    
    const handleInputChange = (event) => {
        handleFormInputChange(event, project, setProject);
    }

    console.log("Project : " + project);
    console.log("Organization: " + props.orgs);


    return (
    <div>
    <h1>Project: {project.title}</h1>

        <form className="form" onSubmit={event => {
                            event.preventDefault()
                            props.handleSubmit(project.id, project)
                        }}>
            <input className="input" name="id"  value={project.id} onChange={handleInputChange}  type="hidden" />
            
            <TextInput name="Title" field_name="title" value={project.title} onChange={handleInputChange}/>
            <CheckboxInput name="Active" field_name="is_active" value={project.is_active} onChange={handleInputChange}/>
            <SelectInput name="Client" field_name="client_id" value={project.client_id} values={props.orgs} onChange={handleInputChange} />

            <input className="submit primary button" type="submit" />
            <br />
        </form>
    </div>
    );
}

export default ProjectForm;