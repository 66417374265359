import React, {useState, useReducer, useEffect} from 'react';
import update from 'react-addons-update';
import ModalNote from './modal-note';
import FavoriteProjectIcon from './favorite-icon';

import {getAuthorizationHeader, findId} from '../utils'
import { API_ENDPOINT } from '../config';
import { toast } from 'react-toastify';
import ProjectList from './project-list';

const TIMESHEET_ENDPOINT = API_ENDPOINT + "/timesheets/"


const SUBMIT_STATUS_OPEN = "OPEN";
const SUBMIT_STATUS_CONFIRM = "CONFIRM";
const SUBMIT_STATUS_SUBMITTING = "SUBMITTING";
const SUBMIT_STATUS_SUBMITTED = "SUBMITTED";
const SUBMIT_STATUS_INVALID = "INVALID";


const favorites = () => {
    let favs = JSON.parse(localStorage.getItem('favorite_projects')); 
    if (favs === null) {
        return [];
    }
    return favs;
}



const SubmitWeekButton = ({status, onSubmitWeek, onBack}) => {
    if (status === SUBMIT_STATUS_OPEN) {
        return <button className="button is-primary" onClick={onSubmitWeek}>Submit Week</button>;
    } else if (status === SUBMIT_STATUS_CONFIRM) {
        return (<div> 
            <button className="button is-primary" onClick={onSubmitWeek}>Confirm</button>
            <button className="button" onClick={onBack}>Back</button>
        </div>);
    } else if (status === SUBMIT_STATUS_SUBMITTING) {
        return <button className="button is-primary" style={{marginLeft: 5}} disabled>Submitting ...</button>;
    } else if (status === SUBMIT_STATUS_INVALID) {
        return (<div> 
            <p style={{'color': 'red'}}>Invalid Timesheet</p>
            <button className="button" onClick={onBack}>Back</button>
        </div>);
    } else {
        return <div />;
    }
}

const getTotals = (projectEntries) => {
    var totals = [0, 0, 0, 0, 0, 0, 0];
    for(var i=0; i<projectEntries.length; i++) {
        var entries= projectEntries[i].entries;
        for(var j=0; j<entries.length; j++) {
            console.log("Total entries: " + entries[j].time);
            totals[j] = totals[j]+entries[j].time;
        }
    }
    return totals;
}

const checkTotals = (projectEntries) => {
    let totals = getTotals(projectEntries);
    for (var i=0; i<totals.length; i++) {
       if (totals[i] != 8) {
           return false;
       } 
    }
    return true;
}

const TotalTimesheet = ({projectEntries, locked}) => {
    let totals = getTotals(projectEntries);
    let visible = totals.reduce(
        (total,num)=>{return total+num}) < 1

    if (visible) {
        return <tr />;
    }
    
    return (
        <tr>
        <td style={{textAlign: 'right'}} >Total
        </td>
        <td></td>
        {
            totals.map((t, i) => {
                let color = 'black';
                if(locked && totals[i]!=8) {
                    color = 'red';
                }
                if(locked && typeof(totals[i])!='number') {
                    color = 'red';
                }
                
                return <td style={{'color': color}} key={"totals-"+i}>
                    {t}
                </td>;
            })
        }           
        </tr>
    );
}

const updateEntryState = (projectEntries, project_id, i, data) => {
    const index = findId(projectEntries, project_id)
    let project = projectEntries[index]
    const new_entries = project.entries.map((item, idx)=>{
        if (idx !== i) {
            // This isn't the item we care about - keep it as-is
            return item;
          }
      
        // Otherwise, this is the one we want - return an updated value
        return data;
    });

    return {...project,
        entries: new_entries
    }
}

const changeProjectState = (projectEntries, project_id, i, data) => {
    const index = findId(projectEntries, project_id)
    let project = projectEntries[index]
    const projects = projectEntries.map((item, idx) => {
        if (idx !== index) {
            // This isn't the item we care about - keep it as-is
            return item;
        }
        // Otherwise, this is the one we want - return an updated value
        return updateEntryState(projectEntries, project_id, i, data);
    })
    console.log("PROJECTS >>>> " + projects);
    return projects;
}


function reducer(state, action) {
    let project_id = null;
    let ts = null;
    let newState = null;
    let i = null;
    switch (action.type) {
        case 'ADD':
            project_id = action.payload.project_id;
            ts = action.payload.ts;
            i = action.payload.i;
            newState = changeProjectState(state, project_id, i, ts);
            return newState;
        case 'UPDATE':
            project_id = action.payload.project_id;
            ts = action.payload.ts;
            i = action.payload.i;
            newState = changeProjectState(state, project_id, i, ts);
            return newState;
        default:
            throw new Error();
    }
}

const  changeEntry = (project, t, i, dispatch) => {
    // TODO keeping the old project state will cause mayhem.
    // we need to store the id and than push changes to the state using the ID.
    // This will prevent races on the data.
    let project_id = project.id;
    let value=t.time;
    let note=t.notes;
    if (t.id) {
        // update the timesheet
        console.log("updating");
        let data = {
            id: t.id,
            notes: note,
            time: parseFloat(value)
        };

        const opts = {
            method: "PUT",
            headers: getAuthorizationHeader(),
            body: JSON.stringify(data)
        };

        fetch(TIMESHEET_ENDPOINT+t.id, opts).then((response)=>{
            if (response.ok) {
                console.log("success");
                toast("Saved");
                if (response.status === 422) {
                    toast.warn("Missing fields");
                    throw Error("Missing Field");
                }
                return response.json();
            } else if (response.status == 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("token_expires");
                window.location.reload();
            }
        }).then((ts)=>{
            dispatch({type: "UPDATE", payload: {project_id, i, ts}});
        }).catch(()=> {
            console.log("ERROR !");
            toast.error("Error adding timesheet entry, Please try again.");
        });
        
    } else {
        console.log("VALUE: " + value);
        console.log("note" + note);
        console.log("date" + t.date);
        // update the timesheet
        let data = {
            notes: note,
            time: parseFloat(value),
            project_id: project.id,
            date: t.date
        };
        console.log("DATA: " + JSON.stringify(data));
        const opts = {
            method: "POST",
            headers: getAuthorizationHeader(),
            body: JSON.stringify(data)
        };
        fetch(TIMESHEET_ENDPOINT, opts).then((response)=>{
            if (response.ok) {
                console.log("success");
                toast("Saved");
                return response.json();
            } else if (response.status == 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("token_expires");
                window.location.reload();
            }
        }).then((ts)=>{
            dispatch({type: "UPDATE", payload: {project_id, i, ts}});
        }).catch(()=> {
            console.log("ERROR !");
            toast.error("Error updating timesheet entry, Please try again.");
            window.location.reload();
        });
    }
}


const WeeklyTimesheet = ({entries}) => {
    const [state, dispatch] = useReducer(reducer, entries)
    // Status can be 
    const [submitWeekStatus, setSubmitWeekStatus] = useState(SUBMIT_STATUS_OPEN)
    const [displays, setDisplays] = useState(
        favorites()
    )
    const [locked, setLocked] = useState(false);

    const projectVisible = (project) => {
        let count = project.entries.reduce((total, el) => {
            console.log(el);
            return total+el.time;
        }, 0);
        console.log("COUNT FOR IS VISIBLE: " + count);
        return count>0 || displays.includes(project.id.toString());
    }

    const selectProject = (e) => {
        const newIncludes = [...displays, e.target.value];
        console.log(newIncludes);
        setDisplays(newIncludes);
    }

    const onSubmitWeek = (ev) => {
        // TODO check if the number of hours for each day is 8
        if (checkTotals(state)){
            setLocked(true);
            setSubmitWeekStatus(SUBMIT_STATUS_CONFIRM);
        } else {
            setLocked(true);
            setSubmitWeekStatus(SUBMIT_STATUS_INVALID);
            
        }
    }

    const onSubmitWeekBack = (ev) => {
        setLocked(false);
        setSubmitWeekStatus(SUBMIT_STATUS_OPEN);
    }
    
    let entriesToDisplay = state.filter(p => p.display);
    let empty = entriesToDisplay === undefined || entriesToDisplay.length == 0;
    let emptyList = (<tr><td colSpan="8">
            <span>Add a project to start tracking your time: </span>
            <select className="select" onChange={selectProject} placeholder="Search Project" >
                    <option value="">Project to Add</option>
                    {state.map((p)=> (
                        <option key={p.id} value={p.id}>{p.title}</option>
                    ))}
            </select>
            </td>
        </tr>);

    console.log("PROJECT ENTRIES: >>> " + entries);
    return (
        <React.Fragment>
            {
                state
                .filter(p => projectVisible(p))
                .map(
                (p) => (<TimesheetsProjectEntry locked={locked} key={p.id} propEntry={p} changeEntry={changeEntry} dispatch={dispatch}/>))
            }
            <TotalTimesheet projectEntries={state} locked={locked}/>

            { emptyList }
            {/* <tr><td><SubmitWeekButton status={submitWeekStatus} onBack={onSubmitWeekBack} onSubmitWeek={onSubmitWeek}/></td></tr> */}
        </React.Fragment>
    )
    
}



const TimesheetsProjectEntry = ({propEntry, locked, changeEntry, dispatch}) => {
    const [project, setProject] = useState(propEntry);

    useEffect(() => {
        setProject(propEntry);
    }, [propEntry]);

    return (
        <tr key={project.id}>
        <td style={{textAlign: 'right'}} >{project.title} 
            <FavoriteProjectIcon projectId={project.id} />
        </td>
        <td></td>
        {
            propEntry.entries.map((t, i) => ( 
                <td key={project.id+"-"+i}>
                    <fieldset disabled={locked}>
                    <TimesheetEntry timesheet={t} changeEntry={(t) => changeEntry(project, t, i, dispatch)}/>
                    </fieldset>
                </td>
            ))
        }           
        </tr>
    )
}



const TimesheetEntry = ({key, timesheet, changeEntry}) =>  {
    let time = timesheet.time;
    let notes = timesheet.notes;

    if (typeof(time) != 'number') {
        time = 0;
    }

    const [value, setValue] = useState({current: time, previous: time});
    const [comment, setComment] = useState(notes);
    useEffect(() => {
        setValue({current: time, previous: time});
    }, [time]);

    const is_valid = (value) => {
        return !isNaN(value) || value === "." || value === "";
    }

    const onChange = (e) => {
        if (is_valid(e.target.value)) setValue({...value, current: e.target.value});
    }

    const onBlur = (e) => {
        if (is_valid(e.target.value)) setValue({...value, current: e.target.value});
        if (e.target.value == ""){
            value.current = 0;
        } else {
            value.current = parseFloat(e.target.value);
        }
        if (value.current == "") {
            value.current = 0;
        }
        

        if (isNaN(value.current)) {
            return;
        }
        if (value.current !== value.previous) {
            let v = value.current;
            if (value.current === "") {
                v = "0";
            }
            let newEntry = {
                ...timesheet,
                time: v,
                notes: comment
            }
            changeEntry(newEntry);
        }
    }

    const changeComment = (notes) => {
        setComment(notes);
        let newEntry = {
            ...timesheet,
            time: value.current,
            notes: notes
        }
        changeEntry(newEntry);
    }

    return (
        <div className="control has-icons-right">
            <input className='hourInputText input'  type='text' 
                onBlur={onBlur}
                onChange={onChange}
                value={value.current?value.current:""} />
            <Note comment={comment} key={key} value={value.current} changeComment={changeComment} />
        </div>);

}




const Note = ({value, comment, changeComment}) => {
    const [modal, setModal] = useState({open: false, text: comment});
    const [changed, setChanged] = useState(false);

    const closeModal = (ev) => {
        setModal({open: false, text:modal.text});
        if (changed) {
            changeComment(modal.text);
            setChanged(false);
        }
        ev.stopPropagation();
    }
    
    const openModal = (ev) => {
        setModal({open:true, text: modal.text});
        ev.stopPropagation();
    }

    const changeText = (ev, comment) => {
        setModal({open:true, text: ev.target.value});
        setChanged(true);
        ev.stopPropagation();
    }

    if (value) {
        const color = comment ? "#7a7a7a" : "#ddd"
        return (
        <div>
        <span onClick={openModal} style={{pointerEvents: 'initial', color: color, zIndex:10}} title={modal.text} className="icon is-small is-right">
            <i className="fa fa-sticky-note" ></i>
        </span>
        
       
        <ModalNote closeModal={(ev) => {closeModal(ev)}} modalState={modal} title="Comment for time tracked" >
            <textarea className="textarea" placeholder="Enter notes" value={modal.text} onChange={changeText} />
        </ModalNote>
        </div>)
    } else {
        return <span />
    }
}

export default WeeklyTimesheet; 