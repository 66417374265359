import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Login from './login';

export const authenticated = () => {
    let token = localStorage.getItem("token", null)
    let token_expires = localStorage.getItem("token_expires", null)
    let result = false;
    if (token && token_expires) {
      if (Number(token_expires) > Date.now()) {
        result = true;
      }
    }

    return result;
}

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_expires");
}

const AuthRoute = ({ component:Component, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={(props) => (authenticated() ?  <Component {...props}/> :  <Login />)
      }
    />
  );
};

export default AuthRoute;
