import React from 'react';
import Table from './table';
import useFetch from '../utils'
import { API_ENDPOINT } from '../config';

const ResourcetList = (props) => {
    const {history, fields, labels, path} = props;
    const url = API_ENDPOINT+path;
    const { data, loading, error } = useFetch(url);
    if (loading) {
        return (
            <span> Loading ... </span>
        )
    }

    if (error) {
        console.log(error);
        return (
            <span> ERROR LOADING DATA ! </span>
        )
    }
    console.log("LABELS: " + labels);
    return (
        <div>
            <div className="columns">
                <div className="column is-8" />
                <div className="column">
                    <div className="select is-pulled-right">
                        <select onChange={() => { console.log("Selected !!!"); history.push(path+'new')} }>
                            <option key="">Actions</option>
                            <option key="delete" >Create New</option>
                        </select>
                    </div>
                </div>
            </div>
            <Table columns={fields} labels={labels?labels:fields} rowClicked="" data={data} link={path} />
            
        </div>
    );
}

export default ResourcetList;