import React, {useState} from 'react';
import useFetch, {getAuthorizationHeader} from '../utils';
import {TextInput, EmailInput} from './fields'
import { API_ENDPOINT } from '../config';
import { toast } from 'react-toastify';
import {Redirect} from 'react-router-dom';
import Button from 'react';

const ORGANIZATION_ENDPOINT = API_ENDPOINT + "/organizations/"

const OrganizationForm = (props) => {
    const url = ORGANIZATION_ENDPOINT
    const [success, setSuccess] = useState(false);
    if (props.match.params.id !== "new") {
        return OrganizationEdit(props);
    }
    
    const organization = {
        id: "",
        name: "",
        email:""

    }

    function handleSubmit(id, data) {
        console.log("Form submitted Mock!" + JSON.stringify(data) + " test");
        fetch(url, {
                method: "POST",
                headers: getAuthorizationHeader(),
                body: JSON.stringify(data)
            }).then((response)=>{
                if (response.ok) {
                    console.log("success");
                    console.log(response);
                    toast.success("Organization created.");
                    setSuccess(true);
                }
            }).catch(()=> {
                console.log("ERROR !")
                toast.error("Error while saving Organization, please try again.");
            });
    }

    if (success) {
        return <Redirect to="/organizations" />;
    }

    return (
        <Organization organization={organization} handleSubmit={handleSubmit} new />
    );
}


const OrganizationEdit = ({match}) => {
    const url = ORGANIZATION_ENDPOINT + match.params.id;
    console.log("URL: " + url);
    const { data, loading, error } = useFetch(url);
    const [success, setSuccess] = useState(false);

    if (loading) {
        return <div>Loading...</div>;
        }
    
    if (error) {
        return <div>Error! {error.message}</div>;
    }

    const handleSubmit = (id, data) => {
        console.log("Form submitted Mock!" + JSON.stringify(data) + " test");
        fetch(url, {
                method: "PUT",
                headers: getAuthorizationHeader(),
                body: JSON.stringify(data)
            }).then((response)=>{
                if (response.ok) {
                    console.log("success");
                    toast.success("Organization saved.");
                }

            }).catch(()=> {
                console.log("ERROR !")
                toast.error("Error while saving Organization, please try again.");
            });

    }

    const onDelete = (id, data) => {
        fetch(url, {
                method: "DELETE",
                headers: getAuthorizationHeader(),
                body: JSON.stringify(data)
            }).then((response)=>{
                if (response.ok) {
                    console.log("success");
                    toast.success("Organization deleted.");
                    setSuccess(true);
                }
            }).catch(()=> {
                console.log("ERROR !")
                toast.error("Error while deleting Organization, please try again.");
            });
    }

    if (success) {
        return <Redirect to="/organizations" />;
    }

    return (
        <Organization organization={data} handleSubmit={handleSubmit} onDelete={onDelete} />
    );
}




const Organization = (props) => {
    console.log(props)
    const [organization, setOrganization] = useState(props.organization)

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOrganization({...organization, [name]: value});
    }


    const onDelete = (event) => {
        event.preventDefault()
        props.onDelete(organization.id, organization)
    }
    let deleteButton = <span/>;
    
    if (!props.new) {
        deleteButton = <button href="#" className="is-danger button" style={{float: "right"}} onClick={onDelete}>Delete</button>;
    }
    
    return (
        <div>
        <h1>Organization: {organization.name}</h1>

            <form className="form" onSubmit={(event) => {
                            event.preventDefault()
                            props.handleSubmit(organization.id, organization)
                        }}>
                <input className="input is-static" name="id"  value={organization.id} onChange={handleInputChange}  type="hidden" />
                <TextInput name="Name" field_name="name" value={organization.name} onChange={handleInputChange}/>
                <TextInput name="Address 1" field_name="address_1" value={organization.address_1} onChange={handleInputChange}/>
                <TextInput name="Address 2" field_name="address_2" value={organization.address_2} onChange={handleInputChange}/>
                <TextInput name="City" field_name="city" value={organization.city} onChange={handleInputChange}/>
                <TextInput name="State" field_name="state" value={organization.state} onChange={handleInputChange}/>
                <TextInput name="Zip Code" field_name="zip" value={organization.zip} onChange={handleInputChange}/>
                <TextInput name="Country" field_name="country" value={organization.country} onChange={handleInputChange}/>
                <TextInput name="Phone" field_name="phone" value={organization.phone} onChange={handleInputChange}/>
                <EmailInput name="Email" field_name="email" value={organization.email} onChange={handleInputChange}/>
                <br />
                
                <input className="submit primary button" type="submit" />
                
                {deleteButton}
            </form>
        </div>
    );
}



export default OrganizationForm;