import React from 'react';

export const TextInput = ({name, field_name, value, onChange}) => (
    <div className="field is-horizontal">
        <div className="field-label is-normal">
        <label className="label">{name}</label>
        </div>
        <div className="field-body">
            <div className="field">
            <div className="control">
                <input className="input" name={field_name}  value={value} onChange={onChange}  type="text" />
                </div>
            </div>
        </div>
    </div>
);


export const CheckboxInput = ({name, field_name, value, onChange}) => (
    <div class="field is-horizontal">
        <div className="field-label">
        <label className="label" for={field_name}>{name}</label>
        </div>
        <div className="field-body">
            <div className="field">
            <div className="control">
                <input className="is-checkradio is-rtl is-large" id={field_name} type="checkbox" name={field_name} checked={value} onChange={onChange} />
                </div>
            </div>
        </div>
        
    </div>
);

export const EmailInput = ({name, field_name, value, onChange}) => (
    <div className="field is-horizontal">

        <div className="field-label is-normal">
            <label className="label">{name}</label>
        </div>
        <div className="field-body">
            <div className="field">
            <div className="control">
            <input className="input" name={field_name}  value={value} onChange={onChange}  type="email" />
            </div>
            </div>
        </div>
    </div>
);

export const SelectInput = ({name, field_name, value, values, onChange}) => (
    <div className="field is-horizontal">
        <div className="field-label is-normal">
        <label className="label">{name}</label>
        </div>
        <div className="field-body">
            <div className="field">
            <div className="control">
            <div className="select">
                <select className="select" value={value} name={field_name} onChange={onChange} placeholder="Select Organization" >
                        <option key="" value="0" selected >Select {name}</option>
                        {values.map((p)=> (
                            <option key={p.id} value={p.id}>{p.name}</option>
                        ))}
                </select> 
            </div>
            </div>
            </div>
        </div>
    </div>
)