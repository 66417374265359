import React, { useState } from 'react';

function arrayRemove(arr, value) {

    return arr.filter(function(ele){
        return ele != value;
    });
  
}

const favorites = () => {
    let favs = JSON.parse(localStorage.getItem('favorite_projects')); 
    if (favs === null) {
        return [];
    }
    return favs;
}

const isFavorite = (projectId) => {
    let favs = favorites()
    return favs.includes(projectId.toString());
}

const FavoriteProjectIcon = ({projectId}) => {
    const [favorite, setFavorite] = useState(isFavorite(projectId));

    const onClick = () => {
        let favs = favorites();
        if (favorite) {
            favs = arrayRemove(favs, projectId);
            setFavorite(false);
        } else {
            favs.push(projectId.toString());
            setFavorite(true);
        }
        localStorage.setItem('favorite_projects', JSON.stringify(favs)); 
    }

    
    if (favorite) {
        return (
            <span className="icon has-text-warning" onClick={onClick}>
                <i className="icon fa fa-star"></i>
            </span>
        );
    } else {
        return (
            <span className="icon " onClick={onClick}>
            <i className="icon fa fa-star"></i>
            </span>
        );
    }
    
}

export default FavoriteProjectIcon;