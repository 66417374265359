import React, {useState} from 'react';
import useFetch, {getAuthorizationHeader, handleFormInputChange} from '../utils';
import {TextInput, EmailInput, SelectInput, CheckboxInput} from './fields'
import { API_ENDPOINT } from '../config';
import { toast } from 'react-toastify';
import {Redirect} from 'react-router-dom';

const orgs_url = API_ENDPOINT + "/organizations/";


const UserForm = (props) => {
    if (props.match.params.id !== "new") {
        return UserEdit(props);
    } else {
        return UserCreate(props);
    }
    
}

const UserCreate = (props) => {
    const url = API_ENDPOINT + "/users/";
    const {data: organizations, loading: org_loading, error: org_error} = useFetch(orgs_url);
    const [success, setSuccess] = useState(false);

    if (org_loading) {
        return <div>Loading...</div>;
        }
    
    if (org_error) {
        return <div>Error! {org_error.message}</div>;
    }

    const user = {
        
        firstname: "",
        lastname: "",
        email:""

    }

    function handleSubmit(id, data) {
        console.log("Form submitted Mock!" + JSON.stringify(data) + " test");
        fetch(url, {
                method: "POST",
                headers: getAuthorizationHeader(),
                body: JSON.stringify(data)
            }).then((response)=>{
                if (response.ok) {
                    console.log("success");
                    setSuccess(true);
                    toast.success("Saved!")
                }
            }).catch(()=> toast("Error !"))
    }

    if (success) {
        return <Redirect to="/users" />
    }

    return (
        <User user={user} orgs={organizations} handleSubmit={handleSubmit} />
    );
}


const UserEdit = ({match}) => {
    const url = API_ENDPOINT + "/users/"+ match.params.id;
    console.log("URL: " + url);
    const {data: organizations, loading: org_loading, error: org_error} = useFetch(orgs_url);
    const { data, loading, error } = useFetch(url);

    if (loading || org_loading) {
        return <div>Loading...</div>;
        }
    
    if (error || org_error) {
        return <div>Error! {error.message}</div>;
    }

    const handleSubmit = (id, data) => {
        console.log("Form submitted Mock!" + JSON.stringify(data) + " test");
        fetch(url, {
                method: "PUT",
                headers: getAuthorizationHeader(),
                body: JSON.stringify(data)
            }).then((response)=>{
                if (response.ok) {
                    toast.success("User Saved !");
                }
            }).catch(()=> toast.error("Error !"));

    }

    console.log(data);
    return (
        <User user={data} orgs={organizations} handleSubmit={handleSubmit} />
    );
}




const User = (props) => {
    const [user, setUser] = useState(props.user)

    const handleInputChange = (event) => {
        handleFormInputChange(event, user, setUser);
    }
    console.log("User : " + user);
    console.log("User is active: " + user.is_active);
    
    return (
        <section class="section">
        <div class="container">
        <h1 class="title">{user.firstname} {user.lastname}</h1>
        
        <form className="form" onSubmit={event => {
                            event.preventDefault()
                            props.handleSubmit(user.id, user)
                        }}>
            <input className="input is-static" name="id"  value={user.id} onChange={handleInputChange}  type="hidden" />
            <TextInput name="First Name" field_name="firstname" value={user.firstname} onChange={handleInputChange}/>
            <TextInput name="Last Name" field_name="lastname" value={user.lastname} onChange={handleInputChange}/>
            <CheckboxInput name="Active" field_name="is_active" value={user.is_active} onChange={handleInputChange}/>
            <CheckboxInput name="Administrator" field_name="is_admin" value={user.is_admin} onChange={handleInputChange}/>
            <EmailInput name="Email" field_name="email" value={user.email} onChange={handleInputChange}/>
            <SelectInput name="Organization" field_name="organization_id" value={user.organization_id} values={props.orgs} onChange={handleInputChange} />
            <TextInput name="Odoo Product" field_name="odoo_product_id" value={user.odoo_product_id} onChange={handleInputChange}/>
            <br />
            <div class="field is-horizontal">
                <div class="field-label">
                </div>
                <div class="field-body">
                    <div class="field">
                    <div class="control">
                    <input className="submit primary button" type="submit" />
                    </div>
                    </div>
                </div>
            </div>
            
        </form>
        </div>
        </section>
    );
}



export default UserForm;