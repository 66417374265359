import {useEffect, useState} from 'react';

export function getAuthorizationHeader() {
    const token = localStorage.getItem('token');
    let authorization =  token ? `Bearer ${token}` : "";
    return {"Authorization": authorization};
}

export function handleFormInputChange(event, state, setState) 
{
  const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
        setState({...state, [name]: checked});
    } else {
        setState({...state, [name]: value});
    }
}

function useFetch(url) {
    const [data, setDataState] = useState(null);
    const [loading, setLoadingState] = useState(true);
    const [error, setErrorState] = useState(false);

    

    useEffect(
      () => {
        const token = localStorage.getItem('token');
        let authorization =  token ? `Bearer ${token}` : "";
        const opts = {
            headers: {
                "Authorization": authorization
            }
        };
        setLoadingState(true);
        fetch(url, opts)
          .then(response => {
              if (response.ok) {
                return response.json();
              }
              console.log(response.status);
              setErrorState(true);
              setLoadingState(false);
              if (response.status!=200) {
                if (response.status == 401) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("token_expires");
                  window.location.reload();
                }
                
              }
          })
          .then(data => {
            if (data===undefined) {
                setErrorState(true);
                setLoadingState(false);
            }
            setDataState(data);
            setErrorState(false);
            setLoadingState(false);
          })
          .catch(error => {
            console.log(error);
            setDataState(null);
            setLoadingState(false);
            setErrorState(true);
          });
      },
      [url]
    );
    return { data, loading, error };
}

export function findId(array, value) {
  for(var i = 0; i < array.length; i += 1) {
      if(array[i]['id'] === value) {
          return i;
      }
  }
  return -1;
}

export default useFetch;
