import React, {useState} from 'react';
import './sass/index.scss';
import './sass/App.scss';
import ProjectForm from './components/project'; 
import AuthRoute, {authenticated, logout} from './auth/authenticatedRoute';
import Login from './auth/login';

import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import AuthApp from './AuthApp';

function App() {
  const [auth, setAuth] = useState({auth: false});

  const disconnect = () => {
    logout();
    setAuth({auth: false});
  }

  if (!authenticated()) {
    return (<Login onLoggedIn={()=> setAuth({auth: true})}/>)
  }

  return (
    <AuthApp disconnect={disconnect}/>
  );
}

export default App;
