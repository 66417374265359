import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import {format, startOfMonth, endOfMonth} from 'date-fns'
import { API_ENDPOINT } from '../config';
import {getAuthorizationHeader} from '../utils';
import { toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";

const RoleReport = ({role}) => (
    <tr>
        <td width="50%">{role.firstname} {role.lastname}</td>
        <td width="30%">{role.odoo_product_id}</td>
        <td width="20%">{role.hours}</td>
    </tr>
);


const ProjectReport = ({project}) => (
    <div>
        <div className="subtitle is-h3">Project Name: {project.project_name}</div>
        <table className="table is-striped">
            <thead>
                <th>Consultant</th>
                <th>Consultant type</th>
                <th>Hours</th>
            </thead>
            <tbody>
                {project.role_reports.map((entry)=>( <RoleReport role={entry} />))}
            </tbody>
        </table>
    </div>
);

const style = {
    borderTop: "8px solid #bbb",
    borderRadius: 5
} 

const InvoiceReport = ({invoice}) => (
    <div>
        <hr style={style} ></hr>
        <div className="title">Invoice</div>
        <h2 className="subtitle">from: {invoice.provider_name} to: {invoice.client_name}</h2>
            {invoice.project_reports.map((entry)=>(<ProjectReport project={entry} />))}
        <div className="is-divider" />
        <br/>
    </div>
);

const TimesheetReportView = () => {
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));
    const [endDate, setEndDate] = useState(endOfMonth(new Date()));
    const [reportData, setReportData] = useState([]);

    const generateReport = (start_date, end_date) => {
        setReportData([]);
        fetch(get_url(start_date, end_date), {
            method: "GET",
            headers: getAuthorizationHeader()
        }).then((response)=>{
            if (response.ok) {
                toast.success("Report Ready");
                return response.json();
            }
        }).then((data)=>{
            setReportData(data);
        }).catch(()=> toast.error("Error !"));
    }

    const get_url = (startDate, endDate) => {
        console.log(startDate + " " + endDate);
        return API_ENDPOINT + "/timesheet-report/new-sage-report?start_date=" + 
        format(startDate, 'YYYY-MM-DD') + 
        "&end_date=" + 
        format(endDate, 'YYYY-MM-DD')
    }

    return (
        <div>
        <section className="section">
            <div className="container">
            <h1 className="title">Timesheet Report</h1>
            
            <form className="form" onSubmit={
                                    event => {
                                        generateReport(startDate, endDate);
                                        event.preventDefault();
                                    }}>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                    <label className="label">Start Date</label>
                    </div>
                    <div className="field-body is-normal">
                        <div className="field">
                            <div className="control"></div>
                            <DatePicker
                                showPopperArrow={false}
                                selected={startDate}
                                onChange={sdate => setStartDate(sdate)} />  
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                    <label className="label">End Date</label>
                    </div>
                    <div className="field-body is-large">
                        <div className="field is-large">
                            <div className="control"></div>
                            <DatePicker
                                showPopperArrow={false}
                                selected={endDate}
                                onChange={edate => setEndDate(edate)}
                            />
                        </div>
                    </div>
                </div>
                <input className="submit primary button" type="submit" value="Generate Report"/>
                </form>
            </div>
        </section>

        {reportData.map((entry=>(<InvoiceReport invoice={entry} />)))}
        
        </div>
    );
  };

  export default TimesheetReportView;