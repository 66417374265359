import React, {useState} from 'react';
import useFetch, {getAuthorizationHeader} from '../utils';
import {TextInput, SelectInput} from './fields';
import { API_ENDPOINT } from '../config';

const orgs_url = API_ENDPOINT + "/organizations/";

const InventoryForm = (props) => {
    if (props.match.params.id !== "new") {
        return InventoryEdit(props);
    } else {
        return InventoryCreate(props);
    }
    
}


const InventoryCreate = ({match}) => {
    const {data: organizations, loading: org_loading, error: org_error} = useFetch(orgs_url);

    if (org_loading) {
        return <div>Loading...</div>;
        }
    
    if (org_error) {
        return <div>Error! {org_error.message}</div>;
    }

    const url = API_ENDPOINT + "/inventories/";
    
    const inventory = {
        id: "",
        name: ""

    }

    function handleSubmit(id, data) {
        console.log("Creating Inventory");
        fetch(url, {
            method: "POST",
            headers: getAuthorizationHeader(),
            body: JSON.stringify(data)
        }).then((response)=>{
            if (response.ok) {
                console.log("success");
            }
        }).catch(()=> console.log("ERROR !"))
    }

    return (
        <Inventory inventory={inventory} orgs={organizations} handleSubmit={handleSubmit} />
    );
}

const InventoryEdit = ({match}) => {
    const url = API_ENDPOINT + "/inventories/" + match.params.id;
    console.log("URL: " + url);
    const { data, loading, error } = useFetch(url);
    const {data: organizations, loading: org_loading, error: org_error} = useFetch(orgs_url);

    if (loading || org_loading) {
        return <div>Loading...</div>;
        }
    
    if (error || org_error) {
        return <div>Error! {error.message}</div>;
    }



    const handleSubmit = (id, data) => {
        console.log("Form submitted Mock!");
        fetch(url, {
            method: "PUT",
            headers: getAuthorizationHeader(),
            body: JSON.stringify(data)
        }).then((response)=>{
            if (response.ok) {
                console.log("success");
            }
        }).catch(()=> console.log("ERROR !"))
    }

    console.log(data);
    return (
        <Inventory inventory={data} orgs={organizations} handleSubmit={handleSubmit} />
    );
}

const Inventory = (props) => {
    console.log("Props: " + props);
    const [inventory, setInventory] = useState(props.inventory)
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInventory({...inventory, [name]: value});
    }

    console.log("Inventory : " + inventory);
    console.log("Organization: " + props.orgs);
    return (
    <div>
    <h1>Inventory: {inventory.name}</h1>

        <form className="form" onSubmit={event => {
                            event.preventDefault()
                            props.handleSubmit(inventory.id, inventory)
                        }}>
            <input className="input is-static" name="id"  value={inventory.id} onChange={handleInputChange}  type="hidden" />
            
            <TextInput name="Title" field_name="name" value={inventory.name} onChange={handleInputChange}/>
            <SelectInput name="Client" field_name="organization_id" value={inventory.organization_id} values={props.orgs} onChange={handleInputChange} />

            <input className="submit primary button" type="submit" />
            <br />
        </form>
    </div>
    );
}

export default InventoryForm;