import React from 'react';
import SelectApps from './select-apps';

const Dashboard = (props) => {
    return ( 
        <div className="grid-container">
          <SelectApps appList={[
            {title: "Time Tracker", description: "Track your time across projects.", link: '/time-tracker'}
            // {title: "Inventories", description: "Track your inventories.", link: '/inventories'},
            // {title: "Paid Time Off", description: "Enter Paid time off.", link: '/paid-time-off'},
            // {title: "Reimbursements", description: "Submit your reimbursements.", link: '/reimbursements'}
          ]}/>
        </div> );
}
 
export default Dashboard;
