import React, {useState} from 'react';
import './sass/index.scss';
import './sass/App.scss';
import NavBar from './components/navbar';
import SideBar from './components/sidebar';
import { BrowserRouter as Router } from "react-router-dom";
import Dashboard from './components/dashboard';
import ProjectList from './components/project-list';
import UserList from './components/user-list';
import ProjectForm from './components/project'; 
import AuthRoute, {authenticated, logout} from './auth/authenticatedRoute';
import Login from './auth/login';
import Timesheets from './components/timesheet-new';
import OrganizationList from './components/organization-list';
import InventoryForm from './components/inventory';
import InventoryList from './components/inventory-list';
import OrganizationForm from './components/organization';
import UserForm from './components/user';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InventoryItemList from './components/inventory-item-list';
import TimesheetReportView from './components/timesheet-report-sage';
import queryString from 'query-string';
import useFetch from './utils';
import { API_ENDPOINT } from './config';

const url = API_ENDPOINT + '/users/me'

export const UserContext = React.createContext({});

const TBI = () => (<div>TO BE IMPLEMENTED</div>);

const AuthApp = ({disconnect}) => {
    const {data, error, loading} = useFetch(url);
    
    
    if (loading) {
        return <div>Loading...</div>;
    };
    
    if (error) {
        return <div>Error! {error.message}</div>;
    };

    return (
    <UserContext.Provider value={data}>
        <Router>
            <div className="App">
            <NavBar logout={disconnect}/>
            <div className='container'>
                <div className="columns">
                <SideBar />
                
                <div className="container column is-10 section" style={{minWidth: 720}}>
                <ToastContainer />
                
                <AuthRoute exact path="/" component={Dashboard} />

                <AuthRoute exact path="/time-tracker" component={Timesheets} />
                <AuthRoute exact path="/inventories" component={InventoryList} />
                <AuthRoute exact path="/reimbursements" component={TBI} />
                <AuthRoute exact path="/paid-time-off" component={TBI} />

                <AuthRoute exact path="/users" component={UserList} />
                <AuthRoute exact path="/projects" component={ProjectList} />
                <AuthRoute exact path="/inventory-items" component={InventoryItemList} />
                <AuthRoute exact path="/organizations" component={OrganizationList} />
                <AuthRoute exact path="/reports" component={TimesheetReportView} />

                <AuthRoute exact path="/users/:id" component={UserForm} />
                <AuthRoute exact path="/projects/:id" component={ProjectForm} />
                <AuthRoute exact path="/inventories/:id" component={InventoryForm} />
                <AuthRoute exact path="/organizations/:id" component={OrganizationForm} />
                <AuthRoute path="/login" component={<Login />} />

                </div>
                </div>
            </div>
            </div>
        </Router>
    </UserContext.Provider>);
}

export default AuthApp;
