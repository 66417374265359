import React from 'react';
import {Link} from 'react-router-dom';

function getDescendantProp(obj, desc) {
    var arr = desc.split(".");
    while(arr.length && (obj = obj[arr.shift()]));
    return obj;
}

const Table = ({data, columns, labels, sorted, link, handleRowClicked}) => {
    handleRowClicked = handleRowClicked ? handleRowClicked : ()=>{console.log("Row clicked!")}
    console.log("LABELS IN TABLE " + labels);
    return (    
        <table className="table">
                <thead>
                    <tr>
                    <th onClick={(ev)=>{console.log(ev.target.id);}} >Edit</th>
                    { labels.map((col, index) => (<th key={col}>{col}</th>))}
                    {/* <th>Select</th> */}
                    </tr>
                </thead>
        
            <tbody>
                {
                    data.map((entry, index) => {
                        return (

                            <tr key={entry.id} onClick={(ev)=>{handleRowClicked(ev, entry.id)}}>
                                <td>
                                    <Link to={`${link}${entry.id}`}>
                                        <span className="icon">
                                            <i className="fa fa-edit" />
                                        </span>
                                    </Link>
                                </td>
                                { columns.map((col, index) => (<td key={index}>{getDescendantProp(entry, columns[index].toLowerCase())}</td>))}
                                {/* <td><input type="checkbox" /></td> */}
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}

export default Table;