import React from 'react';
const ModalNote = ({ children, closeModal, modalState, title }) => {
  if(!modalState.open) {
    return null;
  }
  
  return(
    <div className="modal is-active" style={{zIndex: 1000}}>
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          
        </header>
        <section className="modal-card-body">
          <div className="content">
            {children}
          </div>
        </section>
        <footer className="modal-card-foot">
          <span href="#" className="button is-pulled-right" onClick={closeModal}>Close</span>
        </footer>
      </div>
    </div>
  );
}

export default ModalNote;