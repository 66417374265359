import React from 'react';
import {UserContext} from '../AuthApp';

const NavBar = ({logout}) => (
<nav className="nav" role="navigation" arial-label="main navigation" style={{borderBottom: "2px solid #b32532", boxShadow: "0px 2px 5px #37365b"}}> 
    <div className="container" >
        <div className="nav-left">
        <a href="/" >
          <img src="/dalt-300x300.png" style={{padding: 2, maxHeight: 46, maxWidth: 46}}/>
        </a>
        </div>
    </div>
    
    <div className="nav-right">
      {/* <div className="nav-item">
        <span className="icon"><i className="fa fa-user-circle"></i></span>
      </div> */}
      <UserContext.Consumer>
        {
          (user) => (
            <div className="nav-item">
              <span>Welcome, {user.firstname}</span>
            </div>
          )
        }
      </UserContext.Consumer>
      <div className="nav-item" onClick={logout}>
        <span className="icon"><i className="fa fa-sign-out"></i></span>
      </div>
    </div>
</nav>
);

export default NavBar;