import React from 'react';
import {NavLink} from 'react-router-dom';
import {UserContext} from '../AuthApp';

const AdminMenu = () => {
    return <UserContext.Consumer>
        {(user) => {
            if (user.is_admin) {
                return (
                <React.Fragment>
                    <p className="menu-label">Administration</p>
                    <ul className="menu-list">
                        <li><NavLink to="/users" activeClassName='is-active'>Users</NavLink></li>
                        <li><NavLink to="/organizations" activeClassName='is-active'>Organizations</NavLink></li>
                        <li><NavLink to="/reports" activeClassName='is-active'>Reports</NavLink></li>
                    </ul>
                </React.Fragment>);
            } else {
                return <div />;
            }
        }}
    </UserContext.Consumer>
}


const SideBar = (props) => {
    return (<aside className="column is-2 is-hidden-mobile menu is-fullheight section" style={{minWidth: 210}}>
        
        <ul className="menu-list">
            <li>
                <NavLink exact to="/" activeClassName='is-active'> Dashboard</NavLink>
            </li>

            <li>
                <NavLink to="/time-tracker" activeClassName='is-active'> Time Tracker</NavLink>
            </li>
            <li><NavLink to="/projects" activeClassName='is-active'>Projects</NavLink></li>
            {/* <li><NavLink to="/inventories" activeClassName='is-active'>Projects</NavLink></li>
            <li><NavLink to="/reimbursements" activeClassName='is-active'>Reimbursements</NavLink></li>
            <li><NavLink to="/paid-time-off" activeClassName='is-active'>PTOs</NavLink></li> */}
        </ul>
        
        <AdminMenu />
        
    </aside>)
};

  export default SideBar;
