import React from 'react'
import { GoogleLogin } from 'react-google-login';


const responseSuccess = (response, onLoggedIn) => {
  console.log(response);
  localStorage.setItem("token", response.tokenId);
  localStorage.setItem("token_expires", response.getAuthResponse().expires_at)
  onLoggedIn(true);
}

const responseError = (response) => {
  console.log("Failed !!")
}

const Login = ({onLoggedIn}) => {
  return <div style={{width: '400px', margin: '100px auto 0 auto', textAlign: 'center' }}>
  <img src="/dalt-300x300.png" alt="DALT Web Service" style={{width:200, height:200, marginBottom: 20}}/>
  
  <br/>
  <p>Welcome to Dalt Web Services, please login with your company G-Suite account </p>
  <br />
  <GoogleLogin
        clientId="930792031564-riftac05cqiecc1m0rhgpvl9ot5oon1f.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={(response) => responseSuccess(response, onLoggedIn)}
        onFailure={responseError}
        cookiePolicy={'single_host_origin'}
      /></div>

  }

export default Login;