import React, {useState}  from 'react';
import {startOfWeek, addDays, subDays, format} from 'date-fns'
import { findIndex } from 'lodash';
import WeeklyTimesheet from './timesheet_project_entry'
import useFetch, {getAuthorizationHeader} from '../utils'
import { API_ENDPOINT } from '../config';

const TIMESHEET_ENDPOINT = API_ENDPOINT + "/timesheets/"

const days = ["M", "T", "W", "Th", "F", "S", "Su"]; 

const Timesheets = () => {
    // This is the baseDate.
    const [baseDate, setBaseDate] = useState(
        startOfWeek(new Date(), {weekStartsOn: 1})
        
    );
    
    const url = API_ENDPOINT +
         "/timesheets/user/me" + 
         "?start_date=" + format(addDays(baseDate, 0), 'YYYY-MM-DD') + 
         "&end_date=" + format(addDays(baseDate, 7), 'YYYY-MM-DD');

    const submitted_url = API_ENDPOINT +
         "/submitted_timesheets?date=" + format(addDays(baseDate, 0), 'YYYY-MM-DD');
    
    const {data, error, loading} = useFetch(url)
    // const {reportData, reportError, reportLoading} = useFetch(url)
    

    // display the day in the header
    const day = (i) => {
        const monday = startOfWeek(baseDate, {weekStartsOn: 1})
        let date = addDays(monday, i)
        return format(date, 'MMM-DD');
    }
    // moves the header
    const previousWeek = () => {
        let monday = subDays(baseDate, 7);
        setBaseDate(monday);
    }

    const nextWeek = () => {
        let monday = addDays(baseDate, 7);
        setBaseDate(monday);
        
    }

    const currentWeek = () => {
        let monday = startOfWeek(new Date(), {weekStartsOn: 1})
        console.log(monday);
        setBaseDate(monday);
        
    }

    // transform project to {id:, title:, entries:}
    function projectToEntry(project, startDate) {
        const pid = project.id;
        const title = project.title;
        let count = 0;
        
        const datesArray = [...Array(7)].map((value, i) => {
            const dateStr = format(addDays(startDate, i), "YYYY-MM-DD");
            const index = findIndex(project.timesheets, {date: dateStr});
            if (index === -1) return {time: 0, note: '', date: dateStr};
            count++;
            return project.timesheets[index];
        });

        return {
            id: pid,
            title: title,
            entries: datesArray
        }
    }

    function projectsToEntries(projects) {
        return projects.map((p) => {
            return projectToEntry(p, baseDate);
        });
    }

    if (loading) {
        return <div>Loading...</div>;
    };
    
    if (error) {
        return <div>Error! {error.message}</div>;
    };

    let projectEntries = projectsToEntries(data);

    return (
    <div className="container has-text-left" >
        <h3 className="title">Time Tracker</h3>
        
        <table className="table" style={{maxWidth: '650px'}}>
            <tbody>
            <tr className="table header">
                <th style={{minWidth: "280px"}} className="has-text-center"> 
                <button className="button" style={{textAlign: 'center'}} onClick={currentWeek} >This Week</button>
                </th>
                <th>
                    <span className="timeHeaderControl" onClick={previousWeek}> « </span>
                </th>
                {
                    [...Array(7)].map((baseDate, i) => (<th key={i} className="timeHeaderColumn"><span className="timeHeader">{days[i]}<br /> {day(i)} </span></th> ))
                }
                <th>
                    <span className="timeHeaderControl" onClick={nextWeek}> » </span>
                </th>
            </tr>
            <WeeklyTimesheet entries={projectEntries} />
            
            </tbody>
            
        </table>

        
      </div>);
};

export default Timesheets; 